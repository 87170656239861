@import "assets/scss/material-dashboard.scss";
@import "assets/scss/naxos.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";

body {
  font-size: 0.9rem;
}

.naxos-span-padding {
  padding: 5px;
}

.naxos-span-padding-right {
  padding-right: 5px;
}

.naxos-span-margin-left {
  margin-left: 5px;
}

.naxos-span-padding-left {
  padding-left: 5px;
}

.naxos-i-padding {
  @extend .naxos-span-padding;
}

.naxos-tab-normal {
  background-color: rgba(200, 200, 200, 0.2);
  font-weight: 500;
  border-radius: 4px;
  color: #ffffff;
  top: -2px;
  left: -1vw;
  width: calc((100% - 260px) / 2);
  height: 114%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.naxos-tab-selection {
  background-color: $naxos-background-color-main;
  font-weight: 500;
  border-radius: 4px;
  color: #ffffff;
  top: -2px;
  left: -1vw;
  width: calc((100% - 260px) / 2);
  height: 114%;
  display: flex;
  justify-content: center;
  align-items: center;
}

//-> Buttons
.naxos-btn-normal {
  background: $naxos-background-color-main;
}
.naxos-btn-add {
  background: $naxos-background-color-main;
}
.naxos-btn-modify {
  background: $naxos-background-color-main;
}
.naxos-btn-delete {
  background: #bd0029;
}
.naxos-btn-cancel {
  background: $naxos-background-color-main;
}
.naxos-btn-save {
  background: #008000;
}

.btn {
  color: white;
}

.btn:hover {
  background-color: $naxos-background-color-hover-selection;
  box-shadow: $naxos-box-shadow-hover-selection;
  border-color: $naxos-background-color-hover-selection;
  color: white;
}
//<- Buttons



.naxos-logo {
  height: 70px;
  width: 150px;
}

.naxos-logo-tour {
  height: 70px;
  width: 150px;
  padding: 8px;
  background-color: rgb(44, 54, 123);
}

@media all and (max-width: 991px) {
  //Hide button text on mobile (keep icon only)
  button > span.hide-on-mobile {
    display: none;
  }
}

.IsHeaderIconHidden //Hide icon menu (MENU)
{
  visibility: hidden;
}

.mat-icon-button {
  outline: none !important;
}
.card-content {
  padding: 10px;
}
.category {
  padding-left: 10px;
  margin: 0px;
}

.main-panel .content-panel .main-content {
  margin-top: 0;
}

.panel .accordion-group button {
  // color: white;
  color: black;
}

.panel .accordion-group {
  background-color: white;
}

.carousel-control-prev,
.carousel-control-next {
  width: 3%;
}

.carousel-inner .item {
  margin-bottom: 1rem;
}

app-contact-form textarea {
  min-height: 200px;
}

ng-material-multilevel-menu
  > .amml-container
  > .mat-list
  > ng-list-item:first-child
  > .amml-menu-container {
  margin: 15px 0;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  opacity: 0.6 !important;
  background-color: transparent !important;
  display: block !important;
  z-index: 1040;
}

.sidebar-mini .sidebar .ps__rail-x,
.sidebar-mini .sidebar .ps__rail-y {
  display: none !important;
}

.sidebar-mini .sidebar:hover .ps__rail-y {
  display: block !important;
}

.sidebar-mini .menu > .menu-name {
  opacity: 0 !important;
}

.sidebar-mini .sidebar:hover .menu > .menu-name {
  opacity: 1 !important;
}

.sidebar[data-background-color="black"] {
  .menu:after {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .sidebar-wrapper > .menu > .menu-name {
    color: $white-color !important;
  }
}
.sidebar[data-background-color="red"] {
  .menu:after {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .sidebar-wrapper > .menu > .menu-name {
    color: $white-color !important;
  }
}
.sidebar[data-background-color="white"] {
  .sidebar-wrapper > .menu > .menu-name {
    color: $black-color !important;
  }

  .sidebar-wrapper > .menu i {
    color: $black-color;
  }
}

.sidebar-background {
  display: none !important;
}

.sidebar-invisible .sidebar {
  display: none;
}

.sidebar-invisible .main-panel {
  width: 100%;
}

.sidebar-invisible #navbarNaxos {
  width: 100%;
}

.sidebar-invisible .navbar-minimize {
  display: none !important;
}

.sidebar-mini #navbarNaxos {
  width: calc(100% - 80px);
}

#navigation {
  margin: 0 10px !important;
}

.navbar-wrapper {
  margin-right: auto !important;
}

.navbar-minimize button {
  margin: 0 !important;
}

@media (max-width: 991px) {
  .sidebar::before {
    background-color: #ffffff  !important;
  }
}

.cdk-global-overlay-wrapper {
  z-index: 1000 !important;
}

app-card-form .ps__rail-y {
  right: 24px !important;
}
