//Main background color (pantone)
$naxos-background-color-main: #2d338e; // rgba(45, 51, 142, 255)
$naxos-background-color-main-light: rgba(45, 51, 142, 200) !default;
//Main background color selection
$naxos-background-color-hover-selection: rgb(218, 134, 49) !default;
//Shadow of selected item on hover
$naxos-box-shadow-hover-selection: 
-1px 0px 5px 1px rgba(218, 134, 49, 0.5);

$naxos-background-color-hover-menuicon:  rgb(255, 255, 255);
$naxos-forecolor-hover-menuicon:  rgb(0, 0, 0);

//Shadow of elements
$naxos-box-shadow-element: 
0 16px 26px 0px rgba(184, 228, 63, 0.56),
0 4px 25px 0px rgba(0, 0, 0, 0.12), 
0 8px 10px 0px rgba(221, 218, 20, 0.2);

$brand-primary: $naxos-background-color-main;


$naxos-email-pattern: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";